import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Instances from '../Instances';

let Header = ({ instanceId, setInstanceId }) => {
  const history = useHistory();
  const token = localStorage.getItem("token");

  const onLogout = async () => {
    console.log({ token });
    try {
      await axios.post(`/auth/invalidate-token`, { token });
    } catch (error) {
      console.log(error);
    }
    localStorage.clear();
    history.push("/");
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" flexGrow={1} flexDirection="row">
          <Typography variant="h6">PwC Crisis App</Typography>
          {token && (
            <Instances instanceId={instanceId} setInstanceId={setInstanceId} />
          )}
        </Box>
        {token && (
          <Button color="inherit" onClick={onLogout}>
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
