import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import {useParams, useHistory} from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import _ from 'lodash'

import SelectIcon from '../../components/SelectIcon';
import Modal from '../../components/Modal'

let AddOrEditClient = ({instanceId}) => {
  const [client, setClient] = useState({
    name: '',
    icon: '',
    contacts: [],
  });
  const { id: clientId } = useParams();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (clientId && instanceId) {
      axios.get(`/api/clients/${clientId}`, {params: {instanceId}} )
        .then(({ data: { client: { icon, ...client } } }) => {
        setClient({ ...client, icon: icon ? icon.toString() : '' });
      })
        .catch(error => {
          history.push('/clients')
        })
    }
  }, [clientId, instanceId]);

  function hasDuplicateEmails() {
    const emailAddresses = client.contacts.map(contact => contact.email)
    return _.uniq(emailAddresses).length !== emailAddresses.length;
  }

  const saveClient = async () => {
    if(hasDuplicateEmails()) {
      return setShowModal(true)
    }
    await axios.post(`/api/clients`, { ...client, icon: client.icon || null }, {params: {instanceId}});
    history.push(`/clients`);
  };

  const updateClient = async () => {
    if(hasDuplicateEmails()) {
      return setShowModal(true)
    }
    await axios.patch(`/api/clients/${clientId}`, { ...client, icon: client.icon || null }, {params: {instanceId}});
    history.push(`/clients`);
  };

  const onChange = (name, e) => {
    setClient({ ...client, [name]: e.target.value });
  };

  const onChangeContact = (index, e, name) => {
    let newContacts = [].concat(client.contacts);
    newContacts[index] = {
      ...newContacts[index],
      [name]: e.target.value,
    };

    setClient({ ...client, contacts: newContacts });
  };

  const addContact = () => {
    let newContacts = [].concat(client.contacts);
    newContacts.push({ name: '', job_title: '', email: '' });

    setClient({ ...client, contacts: newContacts });
  };

  const onDeleteContact = async index => {
    setClient({
      ...client,
      contacts: client.contacts.filter((_, _index) => _index != index),
    });
  };

  return <form noValidate autoComplete="off">
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Unique email addresses">
      <Typography>
          Client users can't have the same email addresses
      </Typography>
    </Modal>
    <Typography variant="h5" component="h3" gutterBottom>
      {clientId ? `Update - ${client.name}` : 'Add Client'}
    </Typography>
    <Paper elevation={0}>
      <TextField 
        onChange={e => onChange('name', e)}
        label="Name"
        value={client.name}
        variant="outlined"
      />
      <SelectIcon
        onChange={e => onChange('icon', e)}
        label="Icon"
        value={client.icon}
        instanceId={instanceId}
      />

      <Typography variant="subtitle1" component="h4" gutterBottom>
        Client Contacts
      </Typography>
          {client.contacts.map((contact, index) => (
            <Box key={index} display="flex" alignItems="center">
                <TextField 
                          onChange={e => onChangeContact(index, e, 'name')}
                          label="Name"
                          value={contact.name}
        variant="outlined"
                />
                <TextField 
                
                          onChange={e => onChangeContact(index, e, 'phone')}
                          label="Phone"
                          value={contact.phone}
        variant="outlined"
                />
                  <TextField
                          onChange={e => onChangeContact(index, e, 'job_title')}
                          label="Job title"
                          value={contact.job_title}
        variant="outlined"
                  />
                  <TextField 

                          onChange={e => onChangeContact(index, e, 'email')}
                          label="E-mail"
                          value={contact.email}
        variant="outlined"
                  />
                  <IconButton aria-label="delete" onClick={() => onDeleteContact(index)}>
                    <DeleteIcon />
                  </IconButton>
            </Box>
          ))}
    <IconButton aria-label="add" onClick={addContact}>
      <AddIcon />
    </IconButton>
  </Paper>
  <Button variant="contained" color="primary" onClick={clientId ? updateClient : saveClient}>
    {clientId ? 'Update' : 'Create'}
  </Button>
  </form>
};

export default AddOrEditClient;

