import './index.css';
import 'typeface-roboto';

import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import NetworkError from './components/NetworkError'
import App from './App';
import {API_URL} from './constants'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#d04a02',
      },
      secondary: {
        main: '#299d8f',
      },
    },
    overrides: {
      MuiFormControl: {
        root: {
          margin: 10,
        },
      },
      MuiSelect: {
        select: {
          minWidth: 120
        }
      },
      MuiPaper: {
        elevation0: {
          marginTop: 10,
          marginBottom: 20,
          padding: 10
        }
      }
    },
  });

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Index() {
    const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(null)
  const [hasDefaultConfig, setHasDefaultConfig] = useState(false);

  useEffect(() => {
    axios.defaults.baseURL = API_URL;

    axios.interceptors.request.use(
      function(config) {
        setIsLoading(true);
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      function(error) {
        setIsLoading(false);
        setNetworkError(error);
        console.log('NetworkError', error);
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      function(response) {
        setIsLoading(false);
        return response;
      },
      function(error) {
        setIsLoading(false);
        console.log('NetworkError', error);
        if (error.response && 401 === error.response.status) {
          localStorage.clear();
          window.location.reload();
        } else {
          setNetworkError(error);
          return Promise.reject(error);
        }
      },
    );
    setHasDefaultConfig(true)
  }, [])

  return <ThemeProvider theme={theme}>
    <Backdrop open={isLoading} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
       <NetworkError error={networkError} setError={setNetworkError} />
       {hasDefaultConfig && <App/>}
    </ThemeProvider>
}

ReactDOM.render(
  <Index />,
  document.getElementById('root'),
);
