export const API_URL = process.env.REACT_APP_API_HOST;

export const scope =
  "openid+profile+email+cloudEmail+country+employeeNumber+locality+ppid+groups+splitgroups+role";
export const client_id = process.env.REACT_APP_IDENTITY_CLIENT_ID;
export const identity_endpoint = process.env.REACT_APP_IDENTITY_ENDPOINT;
export const redirect_uri = process.env.REACT_APP_IDENTITY_REDIRECT_URI;
export const response_type = "code";
export const access_type = "offline";
export const ROLES = {
  ADMIN: "BE_REDALERT_INT_ADMIN",
  SUPER_ADMIN: "BE_REDALERT_SUPER_ADMIN",
};
export const CLOUD_PROVIDERS = ["GOOGLE", "AWS", "AZURE"];
