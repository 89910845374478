import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import {useParams, useHistory} from 'react-router-dom'

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export default function AddOrEditIcon({
  instanceId
}) {
  const history = useHistory();
  const {id: iconId} = useParams();

  const [icon, setIcon] = useState({
    name: '',
    url: '',
    file: null,
    id: '',
  });

  const fileRef = useRef(null);

  useEffect(() => {
    if (iconId && instanceId) {
      axios
        .get(`/api/icons/${iconId}`, {params:{instanceId}})
        .then(({ data: { name, url, id } }) => {
          setIcon({ name, url, id });
        })
        .catch(() => {
          history.push('/icons')
        })
    }
  }, [iconId, instanceId]);

  async function saveIcon() {
    const {
      data: { uploadUrl },
    } = await axios.post(`/api/icons`, {
      name: icon.name,
    }, {
      params: {instanceId}
    });
    await axios.put(uploadUrl, icon.file, {
      headers: {
        'Content-Type': icon.file.type,
      },
    });
    history.push('/icons');
  }

  async function updateIcon() {
    const {
      data: { signedUrl },
    } = await axios.patch(`/api/icons/${iconId}`, {
      id: icon.id,
      name: icon.name,
    }, {
      params:{instanceId}
    });
    await axios.put(signedUrl, icon.file, {
      headers: {
        'Content-Type': icon.file.type,
      },
    });
    history.push('/icons');
  }

  function onFileSelectionChange(e) {
    // Release memory
    if (icon.preview) {
      URL.revokeObjectURL(icon.preview);
    }

    const file = e.target.files[0];
    setIcon({ ...icon, url: '', file, preview: URL.createObjectURL(file) });
  }

  return <form noValidate autoComplete="off">
      <Typography variant="h5" component="h3" gutterBottom>
        {iconId ? `Update - ${icon.name}` : 'Add Icon'}
      </Typography>
      <Paper elevation={0}>
        <TextField
          onChange={e => setIcon({ ...icon, name: e.target.value })}
          label="Name"
          value={icon.name}
          variant="outlined"
        />

        <Typography variant="subtitle1" component="h4" gutterBottom>
          Select image smaller then 2 mb
        </Typography>
        <input
          style={{ display: 'none' }}
          type="file"
          ref={fileRef}
          accept="image/png, image/jpeg"
          onChange={onFileSelectionChange}
        />
        <Button variant="contained" color="primary"  onClick={() => fileRef.current.click()}>
          Select file
        </Button>
        <Box mt={2}>
          {icon.preview && (
            <img
              style={{ width: 70, height: 70, resizeMode: 'contain' }}
              src={icon.preview}
            />
          )}

          {icon.url && (
            <img style={{ width: 70, height: 70, resizeMode: 'contain' }} src={icon.url} />
          )}
        </Box>
      </Paper>
      <Button variant="contained" color="primary" onClick={iconId ? updateIcon : saveIcon} disabled={!icon.file}>
        {iconId ? 'Update' : 'Create'}
      </Button>
    </form>
}
