import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {useParams, useHistory} from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import SelectIcon from '../../components/SelectIcon';

export default function AddOrEditCrisisItem({
  instanceId
}) {
  const classes = useStyles()
  const history = useHistory()
  const {id: crisisId} = useParams();

  const [crisis, setCrisis] = useState({
    id: '',
    title: '',
    phone: '',
    icon: '',
    order: '',
    checklists: [],
    subItems: [],
  });

  const [tabSelection, setTabSelection] = useState(0);

  useEffect(() => {
    if (crisisId && instanceId) {
      const crisisPromise = axios
        .get(`/api/checklists/?crisis_id=${crisisId}`, {params:{instanceId}})

      const subCrisisPromise = axios
              .get(`/api/subcrisisitems/${crisisId}`, {params:{instanceId}})

      Promise.all([crisisPromise, subCrisisPromise])
        .then(
          ([{
            data: {
              data: { id, phone, title, checklists, order, icon },
            },
          }, {data: {subCrisisItems}}]) => {
                setCrisis({
                  id: id || '',
                  title: title || '',
                  phone: phone || '',
                  icon: icon ? icon.toString() : '',
                  order: order ? order.toString() : '',
                  checklists,
                  subItems: subCrisisItems,
                });
                setTabSelection(subCrisisItems.length > 0 ? 1 : 0)
          },
        )
        .catch(error => {
          history.push('/crisis-items')  
        })
    }
  }, [crisisId, instanceId]);

  const update = async () => {
    await axios.patch(
      `/api/crisisitems/${crisisId}`,
      {
        ...crisis,
        order: crisis.order || null,
        icon: crisis.icon || null,
      }, {
        params: {
          instanceId
        }
      }
    );
    history.push('/crisis-items');
  };

  const save = async () => {
    await axios.post(
      `/api/crisisitems`,
      {
        ...crisis,
        order: crisis.order || null,
        icon: crisis.icon || null,
      },
      {
        params: {
          instanceId
        }
      }
    );
    history.push('/crisis-items');
  };

  // CRISIS

  // Change crisis properties
  const onChange = (name, e) => {
    setCrisis({ ...crisis, [name]: e.target.value });
  };

  // CHECKLIST

  // Change crisis checklist properties
  const onChangeChecklist = (index, key, e) => {
    setCrisis({
      ...crisis,
      checklists: crisis.checklists.map((c, i) =>
        i !== index ? c : { ...c, [key]: e.target.value },
      ),
    });
  };

  // Delete checklist of crisis
  const onDeleteCrisisChecklist = index => {
    setCrisis({
      ...crisis,
      checklists: crisis.checklists.filter((_, i) => i !== index),
    });
  };

  const addCrisisChecklist = () => {
    setCrisis({
      ...crisis,
      checklists: [...crisis.checklists, { title: '', content: '' }],
    });
  };

  // SUB CRISIS ITEMS

  // Delete subcrisis item
  const deleteSubCrisisItem = index => {
    setCrisis({
      ...crisis,
      subItems: crisis.subItems.filter((_, i) => i !== index),
    });
  };

  const addSubCrisisItem = () => {
    setCrisis({
      ...crisis,
      subItems: [
        ...crisis.subItems,
        { id: '', title: '', phone: '', checklists: [] },
      ],
    });
  };

  const onChangeSubCrisisMainProperties = (index, key, e) => {
    setCrisis({
      ...crisis,
      subItems: crisis.subItems.map((subItem, i) =>
        i !== index
          ? subItem
          : {
              ...subItem,
              [key]: e.target.value,
            },
      ),
    });
  };

  // SUB CRISIS CHECKLIST

  const addSubCrisisChecklist = index => {
    setCrisis({
      ...crisis,
      subItems: crisis.subItems.map((subItem, i) =>
        i != index
          ? subItem
          : {
              ...subItem,
              checklists: [...subItem.checklists, { title: '', content: '' }],
            },
      ),
    });
  };

  const onChangeSubCrisisChecklist = (
    subItemIndex,
    subItemChecklistIndex,
    key,
    e,
  ) => {
    setCrisis({
      ...crisis,
      subItems: crisis.subItems.map((subItem, subI) =>
        subI != subItemIndex
          ? subItem
          : {
              ...subItem,
              checklists: subItem.checklists.map((checklist, checklistI) =>
                checklistI != subItemChecklistIndex
                  ? checklist
                  : {
                      ...checklist,
                      [key]: e.target.value,
                    },
              ),
            },
      ),
    });
  };

  const onDeleteSubCrisisChecklist = (subItemIndex, subItemChecklistIndex) => {
    setCrisis({
      ...crisis,
      subItems: crisis.subItems.map((subItem, subItemI) =>
        subItemI != subItemIndex
          ? subItem
          : {
              ...subItem,
              checklists: subItem.checklists.filter(
                (_, i) => i != subItemChecklistIndex,
              ),
            },
      ),
    });
  };

  return <form noValidate autoComplete="off">
    <Typography variant="h5" component="h3" gutterBottom>
        {crisisId ? `Update - ${crisis.title}` : 'Add Crisis'}
    </Typography>
    <Paper elevation={0}>
        <TextField
          onChange={e => onChange('title', e)}
          label="Name"
          value={crisis.title}
          variant="outlined"
        />
        {crisis.subItems.length === 0 &&
        <TextField
          onChange={e => onChange('phone', e)}
          label="Hotline"
          value={crisis.phone}
          variant="outlined"
        />
        }
        <SelectIcon
          onChange={e => onChange('icon', e)}
          label="Icon"
          value={crisis.icon == null ? '' : crisis.icon.toString()}
          instanceId={instanceId}
        />
        <FormControl>
          <InputLabel id="crisis-order">Order in list</InputLabel>
          <Select
            labelId="crisis-order"
            value={crisis.order}
            onChange={e => onChange('order', e)}
          >
            <MenuItem value="">-</MenuItem>
            {
            new Array(20)
            .fill(1)
            .map((_, i) => i.toString())
            .map(i => (
              <MenuItem key={i} value={i}>{i}</MenuItem>
              )
              )
            }
          </Select>
      </FormControl>
      
        <Tabs
          value={tabSelection}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newTab) => setTabSelection(newTab)}
        >
          <Tab label="Checklist" />
          <Tab label="Sub Crisis"/>
        </Tabs>
        
        { tabSelection === 0 && <Checklist crisis={crisis} addCrisisChecklist={addCrisisChecklist} onChangeChecklist={onChangeChecklist} onDeleteCrisisChecklist={onDeleteCrisisChecklist} classes={classes} /> }
        { tabSelection === 1 && <SubCrisis crisis={crisis} addSubCrisisItem={addSubCrisisItem} onChangeSubCrisisMainProperties={onChangeSubCrisisMainProperties} deleteSubCrisisItem={deleteSubCrisisItem} addSubCrisisChecklist={addSubCrisisChecklist} onDeleteSubCrisisChecklist={onDeleteSubCrisisChecklist} onChangeSubCrisisChecklist={onChangeSubCrisisChecklist} classes={classes} /> }

    </Paper>
    <Button variant="contained" color="primary" onClick={crisisId ? update: save}>
      {crisisId ? 'Update' : 'Create'}
    </Button>
  </form>
  
}

function Checklist({crisis, addCrisisChecklist, onChangeChecklist, onDeleteCrisisChecklist, classes}) {
  if(crisis.subItems.length > 0 )
    return <div> A crisis cannot have a checklist if it contains sub crisis</div>
    
  return <div>
    {crisis.checklists.map((checklist, index) => (
      <Box key={index.toString()} display="flex" alignItems="center">
        <TextField
          onChange={e => onChangeChecklist(index, 'title', e)}
          label="Headline"
          value={checklist.title}
          variant="outlined"
          className={classes.alignTop}
        />
        <TextField
          onChange={e => onChangeChecklist(index, 'content', e)}
          label="Description"
          value={checklist.content}
          variant="outlined"
          multiline
          fullWidth
        />
        <IconButton aria-label="delete" onClick={() => onDeleteCrisisChecklist(index)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    ))}
    <IconButton onClick={addCrisisChecklist}>
      <AddIcon />
    </IconButton>
  </div>
}

function SubCrisis({crisis, addSubCrisisItem, deleteSubCrisisItem, onChangeSubCrisisMainProperties, addSubCrisisChecklist, onChangeSubCrisisChecklist, onDeleteSubCrisisChecklist, classes}) {
  if(crisis.checklists.length > 0)
    return <p>A Crisis cannot have sub crisis if it has a checklist</p>

  return <div>
    {crisis.subItems.map((subItem, subItemIndex) => (
      <Box key={subItemIndex.toString()}>
        <Box display="flex" alignItems="center">
          <TextField
            onChange={e => onChangeSubCrisisMainProperties(
              subItemIndex,
              'title',
              e,
            )}
            label="Sub Crisis Name"
            value={subItem.title}
            variant="outlined"
          />
          <TextField
            onChange={e =>
              onChangeSubCrisisMainProperties(
                subItemIndex,
                'phone',
                e,
              )
            }
            label="Hotline"
            value={subItem.phone}
            variant="outlined"
          />

          <IconButton aria-label="delete" onClick={() => deleteSubCrisisItem(subItemIndex)}>
            <DeleteIcon />
          </IconButton>
          
          <Button color="secondary" onClick={() => addSubCrisisChecklist(subItemIndex)}>Add Step</Button>
        </Box>

        {
        subItem.checklists.map((checklist, subItemChecklistIndex) => (
        <Box key={subItemChecklistIndex.toString()} display="flex"  className={classes.nested}>
          <TextField
            onChange={e =>
              onChangeSubCrisisChecklist(
                subItemIndex,
                subItemChecklistIndex,
                'title',
                e,
              )
            }
            label="Headline"
            value={checklist.title}
            variant="outlined"
          />

        <TextField
          onChange={e =>
            onChangeSubCrisisChecklist(
              subItemIndex,
              subItemChecklistIndex,
              'content',
              e,
            )
          }
          label="Description"
          value={checklist.content}
          variant="outlined"
          multiline
          fullWidth
        />
          
        <IconButton aria-label="delete" onClick={() =>
          onDeleteSubCrisisChecklist(
            subItemIndex,
            subItemChecklistIndex,
          )}>
            <DeleteIcon />
          </IconButton>

        </Box>
        ))
        }
      </Box>
    ))}
    <IconButton onClick={addSubCrisisItem}>
      <AddIcon />
    </IconButton>
  </div>

}

function Warning() {
  return <Box p={2}>
    <Typography variant="subtitle1" component="h4" gutterBottom>
          Choose between adding a checklist to the crisis or multiple sub crisis
          with a checklist
    </Typography>
  </Box>
}

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  alignTop: {
    alignSelf: 'flex-start'
  }
}));
