import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ProtectedRoute({
  children,
  ...routeProps
}) {
  const token = localStorage.getItem('token');

  if (!token) {
    window.location.href = '/auth';
    return;
  }

  return (
    <Route
      {...routeProps}
    >
      {children}
    </Route>
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.element,
};
